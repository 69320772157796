import { Controller } from "@hotwired/stimulus"
import { disableNextButton, enableNextButton, observeAndDisableNextButton } from "../../utils/button_utils"

// Connects to data-controller="setup-mail--form-button"
export default class extends Controller {

  static targets = ["nextButton", "input"]

  observer = null

  connect() {
    this.observeInputs();

    this.handle();

    // 次へボタン連続クリック対策
    observeAndDisableNextButton(this.nextButtonTarget, () => this.observer.disconnect());
  }

  observeInputs() {
    // DOMの変更を監視するMutationObserverを作成
    this.observer = new MutationObserver(() => {
      this.handle();
    });

    // 監視対象の要素とオプションを設定
    const config = { attributes: true, childList: true, subtree: true };
    this.observer.observe(this.element, config);
  }

  handle() {
    const hasError = this.inputTargets.some(input => input.classList.contains('input-error'));
    const isEmpty = this.inputTargets.some(input => input.value.trim() === '');
    
    // ボタンの活性非活性切り替えに反応しないよう監視を一時停止
    this.observer.disconnect();
    if (isEmpty || hasError) {
      disableNextButton(this.nextButtonTarget)
    } else {
      enableNextButton(this.nextButtonTarget)
    }
    // 監視を再開
    this.observer.observe(this.element, { attributes: true, childList: true, subtree: true });
  }
}
