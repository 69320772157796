/**
 * 次へボタンを無効化する関数
 * @param {HTMLElement} button - 無効化するボタンの要素
 */
export function disableNextButton(button) {
  button.classList.add('button-disabled');
  button.setAttribute('disabled', true);
}

/**
 * 次へボタンを有効化する関数
 * @param {HTMLElement} button - 有効化するボタンの要素
 */
export function enableNextButton(button) {
  button.classList.remove('button-disabled');
  button.removeAttribute('disabled');
}

/**
 * 次へボタンのクリックイベントを監視し、クリック後にボタンを無効化する関数
 * @param {HTMLElement} button - 監視するボタンの要素
 * @param {Function} [option] - クリック時に実行するオプションの関数
 */
export function observeAndDisableNextButton(button, option) {
  button.addEventListener('click', () => {
    if (option) {
      option()
    }
    setTimeout(() => {
      disableNextButton(button);
    }, 1);
  });
}