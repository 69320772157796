import { Controller } from "@hotwired/stimulus"
import { disableNextButton, enableNextButton, observeAndDisableNextButton } from "../../utils/button_utils"

// Connects to data-controller="setup-mail--checkbox-button"
export default class extends Controller {
  static targets = ["checkbox", "nextButton"]

  connect() {
    this.observeCheckboxes();
    this.updateButtonState();

    // 次へボタン連続クリック対策
    observeAndDisableNextButton(this.nextButtonTarget);
  }

  /** チェックボックスの監視を開始 */
  observeCheckboxes() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.updateButtonState.bind(this));
    });
  }

  /** 全てのチェックボックスにチェックが入っていたら次へボタンを有効化 */
  updateButtonState() {
    const allChecked = this.checkboxTargets.every(cb => cb.checked);
    if (allChecked) {
      enableNextButton(this.nextButtonTarget)
    } else {
      disableNextButton(this.nextButtonTarget)
    }
  }
}
