import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-enter-submit"
export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.disableEnterSubmit);
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.disableEnterSubmit);
  }

  /** エンターキーで発火するイベントを無効化 */
  disableEnterSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
