import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-mail--step"
export default class extends Controller {
  static values = { current: String }

  connect() {
    this.adjustProgress(this.currentValue);
  }

  adjustProgress(step) {
    if (typeof adjustProgress === "function") {
      adjustProgress(step);
    } else {
      console.error("adjustProgress function is not defined");
    }
  }
}
