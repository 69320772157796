import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "input",
    "emailMessagesArea",
    "emailErrorMessages",
    "nameMessagesArea",
    "nameErrorMessages",
  ]

  connect() {
    this.endpoint = this.data.get("endpoint")
    this.inputTargets.forEach(input => {
      const type = input.dataset.inputType

      // 初期値に値があればバリデーションを実行
      if (input.value !== "") {
        this.#checkErrors(type)
      }

      input.addEventListener('input', this.#debounce(this.#checkErrors.bind(this, type)))
    })
  }

  /**
   * 短時間に繰り返し呼び出される処理を一定時間遅延させることで、不要な処理の実行を抑制する
   * @param {Function} callback - 実行するコールバック関数
   * @param {number} delay - 遅延時間（ミリ秒）
   * @returns {Function} - デバウンスされた関数
   */
  #debounce(callback, delay = 300) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback.apply(this, args);
      }, delay);
    };
  }

  /**
   * 入力フィールドのエラーをチェックする
   * @param {string} type - 入力フィールドのタイプ
   */
  async #checkErrors(type) {
    const emailLocal = this.#getInputValue('email_local')
    const emailDomain = this.#getInputValue('email_domain')
    const displayName = this.#getInputValue('name')

    const response = await post(this.endpoint, {
      responseKind: "json",
      body: JSON.stringify({
        infobip_setup_store: {
          email_local: emailLocal,
          email_domain: emailDomain,
          name: displayName,
        }
      })
    })
    if (!response.ok) {
      const data = await response.json
      if (!data.errors.hasOwnProperty(type)) {
        this.#removeErrorMessages(type)
        return
      }
      this.#updateErrorMessages(data.errors[type], type)
    }

    if (response.ok) {
      this.#removeErrorMessages(type)
    }
  }

  /**
   * 入力フィールドの値を取得する
   * @param {string} type - 入力フィールドのタイプ
   * @returns {string} - 入力フィールドの値
   */
    #getInputValue(type) {
      const input = this.inputTargets.find(input => input.dataset.inputType === type)
      return input ? input.value : ''
    }


  /**
   * エラーメッセージを更新する
   * @param {Array} errors - エラーメッセージの配列
   * @param {string} type - 入力フィールドのタイプ
   */
  #updateErrorMessages(errors, type) {
    if (errors.length > 0) {
      this.#removeErrorMessages(type)
      
      const targetInput = this.inputTargets.find(input => input.dataset.inputType === type)
      if (targetInput) {
        targetInput.classList.add('input-error')
      }
      
      errors.forEach(error => {
        const li = document.createElement("li")
        li.textContent = error
        li.dataset.type = type

        // メッセージの重複を回避
        const existingMessages = Array.from(document.querySelectorAll(`li[data-type="${type}"]`)).map(li => li.textContent);
        if (existingMessages.includes(error)) {
          return
        }

        if (type === 'email_local' || type === 'email_domain') {
          this.#addErrorsToEmail(li)
        }
        if (type == 'name') {
          this.#addErrorsToName(li)
        }
      })
    } else {
      this.emailMessagesAreaTarget.style.display = "none"
    }
  }

  /**
   * エラーメッセージを削除する
   * @param {string} type - 入力フィールドのタイプ
   */
  #removeErrorMessages(type) {
    // フォームの赤枠を消す
    const targetInput = this.inputTargets.find(input => input.dataset.inputType === type)
    if (targetInput) {
      targetInput.classList.remove('input-error')
    }

    // メッセージを消す
    this.#removeErrorsFromEmail(type)
    this.#removeErrorsFromName(type)
  }

  /**
   * エラーメッセージをメールエリアに追加する
   * @param {HTMLElement} el - エラーメッセージの要素
   */
  #addErrorsToEmail(el) {
    this.emailMessagesAreaTarget.style.display = "flex"
    this.emailErrorMessagesTarget.appendChild(el)
  }

  /**
   * エラーメッセージを表示名に追加する
   * @param {HTMLElement} el - エラーメッセージの要素
   */
  #addErrorsToName(el) {
    this.nameMessagesAreaTarget.style.display = "flex"
    this.nameErrorMessagesTarget.appendChild(el)
  }

  /**
   * メールエリアからエラーメッセージを削除する
   * @param {string} type - 入力フィールドのタイプ
   */
  #removeErrorsFromEmail(type) {
    const errorMessages = this.emailErrorMessagesTarget.querySelectorAll(`li[data-type='${type}']`)
    errorMessages.forEach(message => message.remove())
  }

  /**
   * 表示名エリアからエラーメッセージを削除する
   * @param {string} type - 入力フィールドのタイプ
   */
  #removeErrorsFromName(type) {
    const errorMessages = this.nameErrorMessagesTarget.querySelectorAll(`li[data-type='${type}']`)
    errorMessages.forEach(message => message.remove())
  }

}
