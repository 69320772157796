import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-mail--request-dns-confirmation"
export default class extends Controller {
  static targets = ["nextButton"]

  connect() {
    this.nextButtonTarget.addEventListener('click', () => {
      this.loading();
    });
  }

  loading() {
    Rails.loading($('meta[name=setup-mail-pending-text]').attr('content'));
  }
}
